var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v(_vm._s(_vm.formTitle)),
      ]),
      _c("vx-card", [
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Location Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:100",
                    expression: "'required|max:100'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Location Name" },
                model: {
                  value: _vm.form.locationName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "locationName", $$v)
                  },
                  expression: "form.locationName",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Location Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Search Location*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "w-full",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showLocationAutoComplete = true
                    },
                  },
                },
                [_vm._v("Search Location")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Minimum Star Rating*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  reduce: (option) => option.code,
                  name: "Minimum Star Rating",
                  options: _vm.ratingOptions,
                  clearable: false,
                },
                model: {
                  value: _vm.form.minimumRating,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "minimumRating", $$v)
                  },
                  expression: "form.minimumRating",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Minimum Star Rating"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Is Active")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-switch", {
                model: {
                  value: _vm.form.isActive,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isActive", $$v)
                  },
                  expression: "form.isActive",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "lg:float-left mt-4" }, [
          _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v("*Required Field"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "danger", type: "filled" },
                on: { click: _vm.onCancel },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-2",
                attrs: { color: "primary", type: "filled" },
                on: { click: _vm.onSave },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
      _vm.showLocationAutoComplete
        ? _c("location-editor-auto-complete", {
            attrs: {
              applicationId: _vm.applicationId,
              location: _vm.locationStateForAutoComplete,
              noSave: true,
              geographyType: 2,
            },
            on: {
              onEditSuccess: _vm.onEditSuccessAutoComplete,
              onCancel: function ($event) {
                _vm.showLocationAutoComplete = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }